.lists {
  padding: 100px;
  background-color: white;
  color: black !important;
  h1 {
    font-size: 32px;
  }

  &__choose {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  table {
    thead {
      padding: 0 !important;
      th {
        padding: 0 !important;
      }
    }
    tbody {
      max-height: 70vh;
      overflow: scroll;
    }
  }
}
