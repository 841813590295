.product-overview {
  min-height: 1200px !important;
  @media screen and (max-width: 1280px) {
    height: 1700px !important;
  }
  @media screen and (max-width: 769px) {
    height: 1600px !important;
  }

  &__category {
    display: flex;
    align-items: flex-start;
    height: fit-content;

    @media screen and (max-width: 1280px) {
      display: grid;
      left: 0px;
      padding-left: 40px;
      padding-right: 40px;
      width: fit-content;
      left: auto !important;
      right: auto !important;
      svg {
        width: 100% !important;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        // height: 100% !important;
      }
    }

    @media screen and (max-width: 769px) {
      display: grid;
      right: 0 !important;
      left: 0 !important;
      padding-left: 20px;
      padding-right: 20px;
      gap: 0px !important;
      top: 0px;
      svg {
        width: 100% !important;
      }
    }

    &:last-child {
      @media screen and (max-width: 1280px) {
        top: 1100px;
      }
      @media screen and (max-width: 769px) {
        top: 1000px;
      }
    }

    &__content {
      @media screen and (max-width: 1280px) {
        width: 100% !important;
      }
      @media screen and (max-width: 769px) {
        width: 100% !important;
      }

      h3 {
        color: #e0e0e0;
        font-family: Outfit;
        font-size: 64px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        @media screen and (max-width: 1280px) {
          width: 100% !important;
          font-size: 48px;
          text-align: center;
        }

        @media screen and (max-width: 769px) {
          width: 100% !important;
          max-width: 305px;
          color: #e0e0e0;
          font-family: Outfit;
          font-size: 32px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-align: left;
        }
      }
      p {
        color: #b7b7b7;
        font-family: Inter;
        font-size: 17px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        line-height: 160%;
        @media screen and (max-width: 1280px) {
          width: 100% !important;
          text-align: center;
        }
        @media screen and (max-width: 769px) {
          width: 100% !important;
          color: #fff;
          font-family: General Sans;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 160%;
          text-align: left;
        }
      }
    }

    button {
      width: 203px !important;
      border-radius: 15px;
      border: 1px solid #676767;
      background: linear-gradient(90deg, #432371 0%, #faae7b 100%);
      color: #e0e0e0;
      font-family: Inter;
      font-size: 17px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      @media screen and (max-width: 1280px) {
        margin-left: auto;
        margin-right: auto;
      }

      @media screen and (max-width: 769px) {
        width: 132px !important;
        border-radius: 0px !important;
        color: #e0e0e0;
        font-family: Inter;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  h5 {
    color: #fff;
    font-family: General Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    opacity: 0.8;
    @media screen and (max-width: 1280px) {
      text-align: center !important;
      width: fit-content !important;
      margin-left: auto;
      margin-right: auto;
    }
    @media screen and (max-width: 769px) {
      color: #fff;
      font-family: General Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 160%;
      text-align: left;
      margin-bottom: 0 !important;
      margin-left: 0;
      margin-right: 0;
    }
  }
}
