.auth {
  &__layout {
    display: flex;
    align-items: center;
    gap: 60px;
    padding: 0px 120px;
    padding-bottom: 40px;
    @media screen and (max-width: 1280px) {
      padding: 0px 80px;
      padding-bottom: 40px;
    }
    @media screen and (max-width: 769px) {
      display: block;
      padding: 0px 40px;
      padding-bottom: 40px;
    }
  }

  &__form {
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    @media screen and (max-width: 769px) {
      display: none;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }

  p {
    color: #9090a5 !important;
    font-family: Plus Jakarta Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-top: 16px;
  }

  h3 {
    color: #c2d1d9;
    font-family: Plus Jakarta Sans;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
  }

  label {
    color: #c2d1d9;
    font-family: Plus Jakarta Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 16px;
  }

  input {
    outline: none !important;
  }

  button {
    width: 100%;
    height: 54px;
    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    display: flex;
    align-items: center !important;
    justify-content: center !important;
  }

  &__actions {
    display: flex;
    gap: 10px;
    // margin-top: 80px;
    span {
      color: #c2d1d9;
      font-family: Plus Jakarta Sans;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }

    a {
      color: #4f46e5;
      font-family: Plus Jakarta Sans;
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
    }
  }

  .linkedin-url {
    text-decoration: #4f46e5 underline !important;
    color: #4f46e5 !important;
  }
}
