.our-services {
  height: fit-content;
  @media screen and (max-width: 769px) {
    height: fit-content;
  }
  h3 {
    color: #e0e0e0;
    text-align: center;
    font-family: Outfit;
    font-size: 43.633px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    @media screen and (max-width: 769px) {
      color: #e0e0e0;
      text-align: center;
      font-family: Outfit;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  &__desktop-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 40px;
    margin-left: 51.54px;
    margin-right: 51.54px;
    margin-top: 49px;

    @media screen and (max-width: 1024px) {
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: 769px) {
      display: grid;
      gap: 30px;
      grid-template-columns: 1fr;
      margin-left: 20px !important;
      margin-right: 20px !important;
      width: fit-content;
      margin-top: 49px;
    }
  }

  &__mobile-list {
    @media screen and (max-width: 769px) {
      display: grid;
      gap: 30px;
      margin-left: auto !important;
      margin-right: auto !important;
      width: fit-content;
      margin-top: 49px;
    }
  }
}
