.hero-section {
  padding-top: 150px;
  @media screen and (max-width: 769px) {
    padding-top: 80px;
  }
  span {
    text-align: center;
    font-family: Outfit !important;
    font-size: 53px;
    font-style: normal;
    font-weight: 600;
    line-height: 70px;
    @media screen and (max-width: 1280px) {
      font-size: 48px;
    }
    @media screen and (max-width: 769px) {
      color: #e0e0e0;
      text-align: center;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 48px;
      margin-left: 0 !important;
    }
  }
  p {
    color: #b7b7b7;
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 725px;
    @media screen and (max-width: 1280px) {
      font-size: 16px;
      width: 90%;
    }

    @media screen and (max-width: 769px) {
      color: #b7b7b7;
      text-align: center;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      width: 100%;
    }
  }

  &__buttons {
    @media screen and (max-width: 769px) {
      display: grid;
      width: fit-content;
      margin-left: auto !important;
      margin-right: auto;
      gap: 15px;
    }
    button {
      @media screen and (max-width: 769px) {
        color: #e0e0e0;
        font-family: Inter;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0.3;
      color: white;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0.3;
      color: white;
    }
    100% {
      opacity: 1;
    }
  }

  &__animation-btn {
    -webkit-animation-name: fadeIn;
    -webkit-animation-duration: 2s;
    animation-name: fadeIn;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    animation-direction: alternate-reverse;
    animation-iteration-count: infinite;
  }

  &__animation-1 {
    -webkit-animation-name: fadeIn;
    -webkit-animation-duration: 2s;
    animation-name: fadeIn;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    background: var(--linear, linear-gradient(90deg, #432371 0%, #faae7b 100%));
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    animation-timing-function: ease-in-out;
    animation-direction: alternate-reverse;
    animation-iteration-count: infinite;
  }

  &__animation-2 {
    -webkit-animation-name: fadeIn;
    -webkit-animation-duration: 2s;
    animation-name: fadeIn;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    background: var(
      --Gradient-1,
      linear-gradient(90deg, #2b59ff 0%, #bb2bff 100%)
    );
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    animation-timing-function: ease-in-out;
    animation-direction: alternate-reverse;
    animation-iteration-count: infinite;
  }

  &__animation-3 {
    background: var(
      --Gradient-1,
      linear-gradient(90deg, #3a7343 0%, #a36f14 100%)
    );
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-animation-name: fadeIn;
    -webkit-animation-duration: 2s;
    animation-name: fadeIn;
    animation-duration: 2s;
    // animation-delay: 1s;
    animation-timing-function: ease-in-out;
    animation-direction: alternate-reverse;
    animation-iteration-count: infinite;
  }
}
