.banner {
  height: 592px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 737px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  div {
    background-image: url("./BannerBg/BannerBg.svg");
    background-size: contain;
  }
  @media screen and (max-width: 769px) {
    width: 100%;
    height: 400px !important;
  }
  h3 {
    color: #fff;
    text-align: center;
    font-family: Plus Jakarta Sans;
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    text-align: center;
    margin-bottom: 41px;
    @media screen and (max-width: 769px) {
      color: #e0e0e0;
      text-align: center;
      font-family: Outfit;
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      width: 312px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 22px;
    }
  }

  button {
    color: #fff;
    text-align: center;
    font-family: General Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 32px */
    display: flex;
    width: 210px;
    height: 73px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width: 769px) {
      display: flex;
      width: 176px;
      height: 47px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      border-radius: 15px;
      border: 1px solid #676767;
      background: var(
        --Linear,
        linear-gradient(90deg, #432371 0%, #faae7b 100%)
      );
      color: #fff;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}
