.header {
  &__logo {
    gap: 16px;
    span {
      color: #fff;
      font-feature-settings: "clig" off, "liga" off;
      font-family: Donegal One;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
